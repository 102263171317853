import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Trail from "./Trail";
import SearchBar from "./Searchbar";
const { Zilliqa } = require("@zilliqa-js/zilliqa");

// const HeroTitle = styled.h1`
//   font-size: 2rem;
//   font-weight: 500;
// `;

const ServerWrapper = styled.div`
  position: absolute;
  top: 3%;
  right: 3%;
  transition: all 200ms ease;
  z-index: 1000;
`;

const ServerButton = styled.button`
  float: right;
  clear: right;
  height: 36px;
  border: 3px solid #12638f;
  background: #12638f;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  font-family: inherit;
  &:hover {
    opacity: 90%;
  }
  &:focus {
    outline: none;
    border: 3px solid white;
  }
  transition: all 200ms ease;
`;

const NetworkWrapper = styled.div`
  text-align: left;
  position: absolute;
  top: 3%;
  left: 3%;
  transition: all 200ms ease;
  z-index: 1000;
`;

const NetworkButton = styled.button`
  cursor: pointer;
  width: auto;
  height: 36px;
  border: 3px solid #12638f;
  background: #12638f;
  text-align: center;
  color: #fff;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-family: inherit;
  &:hover {
    opacity: 90%;
  }
  &:focus {
    outline: none;
    border: 3px solid white;
  }
  transition: all 200ms ease;
`;

const TableWrapper = styled.table`
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background-color: #12638f;
  color: white;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  font-size: 16px;
  transition: all 200ms ease;
`;

const InputWrapper = styled.input`
  width: 200px;
  border-bottom: 1px solid white;
  border-right: none;
  border-left: none;
  border-top: none;
  padding: 5px;
  height: 20px;
  outline: none;
  color: white;
  background-color: #12638f;
  font-size: 16px;
  font-family: inherit;
  transition: all 200ms ease;
`;

const Server: React.FC = () => {
  const [dict, setDict] = useState<any>();
  const [showMenu, setShowMenu] = useState<boolean>();
  const [server, setServer] = useState<string>();
  const [env, setEnv] = useState<string>();
  const [configList, setConfigList] = useState<any>();
  const [admins, setAdmins] = useState<any>();
  const [contractError, setContractError] = useState<boolean>(false);
  const [tempNetwork, setTempNetwork] = useState<string>();
  const [tempBlockchain, setTempBlockchain] = useState<string>();
  const [tempAddress, setTempAddress] = useState<string>();
  const [showNetwork, setShowNetwork] = useState<boolean>(false);

  useEffect(() => {
    const getContractAndSetServer = async () => {
      try {
        const response = await fetch("/config.json");
        const configFile = await response.json();
        setDict(configFile.networks_config);
        setServer(Object.keys(configFile.networks_config)[0]);
        setEnv(configFile.deploy_env);
      } catch (error) {}
    };
    getContractAndSetServer();
  }, []);

  useEffect(() => {
    const changeServer = async () => {
      let array: string[] = [];
      if (dict) {
        Object.entries(dict).forEach(([key, value]) => {
          array.push(key);
        });
        setConfigList(array);
      }
    };
    changeServer();
  }, [dict]);

  useEffect(() => {
    const implementServer = async () => {
      try {
        if (dict && server) {
          let zilliqa = new Zilliqa(dict[server].blockchain);
          let deployedManagerTrackingContract = await zilliqa.contracts.at(
            dict[server].manager_tracking_contract
          );
          if (deployedManagerTrackingContract.address === undefined) {
            setContractError(true);
          }
          const adminsTemp = await deployedManagerTrackingContract.getSubState(
            "admins"
          );
          setAdmins(adminsTemp);
        }
      } catch (error) {
        setContractError(true);
      }
    };
    implementServer();
  }, [dict, server]);

  function reload() {
    window.location.reload();
  }

  const handleShowMenu = async (event) => {
    event.preventDefault();
    setShowMenu(!showMenu);
  };

  const handleChangeServer = (event) => {
    event.preventDefault();
    console.log(configList);
    console.log(event.target.innerText);
    setServer(event.target.innerText);
    setShowMenu(!showMenu);
  };

  const handleShowNetwork = () => {
    setShowNetwork(!showNetwork);
  };
  const handleChangeTempNetwork = async (event) => {
    setTempNetwork(event.target.value);
  };

  const handleChangeTempBlockchain = async (event) => {
    setTempBlockchain(event.target.value);
  };

  const handleChangeTempAddress = async (event) => {
    setTempAddress(event.target.value);
  };

  const handleAddServer = () => {
    addServer(tempNetwork, tempBlockchain, tempAddress);
    setShowNetwork(false);
  };

  async function addServer(network, blockchain, manager_tracking_contract) {
    let tempDict: JSON = dict;
    tempDict[network] = {};
    tempDict[network]["blockchain"] = blockchain;
    tempDict[network]["manager_tracking_contract"] = manager_tracking_contract;
    setDict(tempDict);
    let array: string[] = [];
    if (dict) {
      Object.entries(dict).forEach(([key, value]) => {
        array.push(key);
      });
      setConfigList(array);
    }
  }

  if (contractError) {
    return (
      <Trail>
        <p>Network/Contract not found</p>
        <p
          style={{
            fontWeight: 700,
            cursor: "pointer",
          }}
          onClick={() => reload()}
        >
          Try again?
        </p>
      </Trail>
    );
  }
  return (
    <>
      <ServerWrapper>
        <ServerButton type="submit" onClick={handleShowMenu}>
          {server}
        </ServerButton>
        {showMenu ? (
          <div>
            {configList.map((server: string, index: number) => {
              return (
                <ServerButton
                  type="submit"
                  key={index}
                  onClick={handleChangeServer}
                >
                  {server}
                </ServerButton>
              );
            })}
          </div>
        ) : null}
      </ServerWrapper>
      <NetworkWrapper>
        {env === "stg" ? (
          <>
            <NetworkButton onClick={handleShowNetwork}>
              Add your own network
            </NetworkButton>
            <>
              {showNetwork ? (
                <div>
                  <TableWrapper className="table">
                    <tbody>
                      <tr>
                        <td>Network: </td>
                        <td>
                          &nbsp;
                          <InputWrapper
                            type="text"
                            onChange={handleChangeTempNetwork}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Blockchain: </td>
                        <td>
                          &nbsp;
                          <InputWrapper
                            type="text"
                            onChange={handleChangeTempBlockchain}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Contract Address: </td>
                        <td>
                          &nbsp;
                          <InputWrapper
                            type="text"
                            onChange={handleChangeTempAddress}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </TableWrapper>
                  <NetworkButton onClick={handleAddServer}>
                    Add Network
                  </NetworkButton>
                </div>
              ) : null}
            </>
          </>
        ) : null}
      </NetworkWrapper>
      <SearchBar admins={admins} />
    </>
  );
};

export default Server;
