import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Trail from "./Trail";
import Spinner from "./Spinner/Spinner";
import AdminMessage from "./AdminMessage";

const SearchBarWrapper = styled.div`
  margin: 0px 0px 20px 0px;
`;

const InnerWrapper = styled.div`
  margin: auto;
`;

const IDInput = styled.input`
  font-family: inherit;
  border: 3px solid #12638f;
  border-radius: 10px;
  text-align: left;
  background: white;
  border-right: none;
  padding: 5px;
  height: 36px;
  border-radius: 10px 0 0 10px;
  outline: none;
  color: #12638f;
  font-size: 16px;
  margin: 0px -10px 0px 0px;
  transition: all 200ms ease;
  &:focus {
    color: #12638f;
  }
`;

const IDSubmit = styled.button`
  font-family: inherit;
  border-radius: 10px;
  cursor: pointer;
  width: 80px;
  height: 36px;
  border: 3px solid #12638f;
  background: #12638f;
  text-align: center;
  color: #fff;
  font-size: 16px;
  &:focus {
    outline: none;
    border: 3px solid white;
  }
  transition: all 200ms ease;
`;

const IDForm = styled.div`
  font-family: inherit;
  gap: 10px;
`;

const Footer = styled.div`
  margin: 20px auto;
`;

interface Props {
  admins: any;
}

const SearchBar: React.FC<Props> = ({ admins }) => {
  const [idInput, setIDInput] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [tempIdInput, setTempIdInput] = useState<string>();
  useEffect(() => {}, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSearched(false);
    setIsAdmin(admins.admins[idInput] !== undefined);
    setTempIdInput(idInput);
    setSearched(true);
  };

  return (
    <>
      <SearchBarWrapper>
        <InnerWrapper>
          {admins ? (
            <Trail>
              <IDForm>
                <IDInput
                  type="text"
                  value={idInput}
                  onChange={(e) => {
                    setIDInput(e.target.value);
                  }}
                  placeholder="@telegram_id"
                  name="tid"
                />
                <IDSubmit type="submit" onClick={handleSubmit}>
                  Search
                </IDSubmit>
              </IDForm>
            </Trail>
          ) : (
            <Trail>
              <Spinner />
              loading
            </Trail>
          )}
        </InnerWrapper>
      </SearchBarWrapper>
      <AdminMessage
        searched={searched}
        isAdmin={isAdmin}
        tempIdInput={tempIdInput}
      />
      <Footer>
        Powered by
        <a
          href="https://zilliqa.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <strong>Zilliqa</strong>{" "}
        </a>
        and
        <a
          href="https://scilla-lang.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <strong>Scilla</strong>{" "}
        </a>
      </Footer>
    </>
  );
};

export default SearchBar;
