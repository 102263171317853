import React, { useEffect } from "react";
import styled from "styled-components";
import Trail from "./Trail";

const AdvisoryStyle = styled.div`
  position: relative;
  border-radius: 10px;
  border: 0px;
  margin: auto;
  width: 55%;
  min-width: 450px;
  text-align: left;
  padding: 10px;
  background-color: #0d4a6b;
  box-shadow: 1px 2px 6px 7px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0);
`;

const AdvisoryWrapper = styled.div`
  margin: 20px 0px 0px 0px;
`;

function Advisory() {
  return (
    <AdvisoryStyle>
      <p>
        <strong>
          <u>Advisory</u>
        </strong>
        <br></br>
        <br></br>
        1) Do not send $ZIL or any token to anyone you do not know
        <br></br>
        <br></br>
        2) Do not give your private key or 12/24 letter mnemonic words to anyone
        <br></br>
        <br></br>
        3) There is no Zilliqa support on telegram. Anyone who DMs you
        pretending to be one is a scam
        <br></br>
        <br></br>
        4) Refer to{" "}
        <a
          href="https://t.me/zilliqa"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <strong>https://t.me/zilliqa</strong>
        </a>{" "}
        for the official announcement
        <br></br>
        <br></br>
        <strong>
          <u>What to do if you encounter a scam?</u>
        </strong>
        <br></br>
        <br></br>
        1) Do not provide any information
        <br></br>
        <br></br>
        2) Report the scam address to{" "}
        <a
          href="https://cryptoscamdb.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <strong>https://cryptoscamdb.org/</strong>
        </a>{" "}
        and{" "}
        <a
          href="security@zilliqa.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <strong>security@zilliqa.com</strong>
        </a>
        <br></br>
        <br></br>
        <strong>
          <u>Common scams</u>
        </strong>
        <br></br>
        <br></br>
        1) Zilliqa support DMs you to assist you in staking or token swaps
        <br></br>
        <br></br>
        2) Free $ZIL airdrops
        <br></br>
        <br></br>
        3) Fake wallets
      </p>
    </AdvisoryStyle>
  );
}

interface Props {
  searched: boolean;
  isAdmin: boolean;
  tempIdInput: string | undefined;
}

const AdminMessage: React.FC<Props> = ({ searched, isAdmin, tempIdInput }) => {
  useEffect(() => {}, []);

  return (
    <>
      {searched ? (
        <Trail>
          {isAdmin ? (
            <>
              <strong>{tempIdInput}</strong> is a known Telegram admin handle.{" "}
            </>
          ) : (
            <>
              <strong>{tempIdInput}</strong> is NOT a known Telegram admin
              handle.
            </>
          )}
          <AdvisoryWrapper>
            <Advisory />
          </AdvisoryWrapper>
        </Trail>
      ) : null}
    </>
  );
};

export default AdminMessage;
