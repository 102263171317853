import React from "react";
import styled from "styled-components";
import Zil from "./assets/zil.png";
import Server from "./Components/Server";

const AppWrapper = styled.div`
  padding: 40px 30px 0px 30px;
  max-width: 1600px;
  margin: auto;
  text-align: center;
  min-height: 100vh;
`;

const Image = styled.img`
  width: 100px;
  margin: 0px 0px 20px 0px;
`;

export default function App() {
  return (
    <AppWrapper>
      <Image alt="aas" src={Zil} />
      <Server />
    </AppWrapper>
  );
}
